<template>
  <div class="current-portal-logo">
    <a
      v-if="currentPortal.productUrl"
      :href="currentPortal.productUrl"
      target="_blank"
    />

    <img
      v-if="currentPortal.imageUrl"
      class="current-portal-logo__img"
      :src="currentPortal.imageUrl"
      :alt="currentPortal.name"
      @error="onImageError"
    >

    <span>{{ currentPortal.name }}</span>
  </div>
</template>

<script>
import brokenImageMixin from '@/mixins/broken-image';

export default {
  name: 'CurrentPortalLogo',

  mixins: [brokenImageMixin],

  props: {
    currentPortal: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.current-portal-logo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  position: relative;
  text-transform: uppercase;

  &__img {
    display: inline-block;
    margin-right: 10px;
    width: auto;
    height: auto;
    max-width: 24px;
    max-height: 24px;
  }

  a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
