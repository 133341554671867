export default {
  name: 'BrokenImageMixin',
  methods: {
    onImageError(event) {
      const $img = event.target;

      $img.classList.add('broken-image');
    },
  },
};
